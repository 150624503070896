import React from 'react'
import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import FashionSubheader from '../components/FashionSubheader'
import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgWorkCover from '../assets/image/cover/LenaIntro.webp'
import imgS1 from '../assets/image/details/kikimora/Lena6.jpg'
import imgS2 from '../assets/image/details/kikimora/Lena.jpg'
import imgS3 from '../assets/image/details/kikimora/Lena2.jpg'
import imgS4 from '../assets/image/details/kikimora/Lena5.jpg'
import imgS5 from '../assets/image/details/kikimora/Lena3.jpg'
import imgS6 from '../assets/image/details/kikimora/Lena4.jpg'
import imgS7 from '../assets/image/details/kikimora/Lena7.jpg'
import imgS8 from '../assets/image/details/kikimora/Transparent-Layout.png'

const WorkSingle = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <img src={imgWorkCover} alt="" className="img-fluid w-100" />
        </Container>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">STYLING</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                  Kikimora is a contemporary lifestyle magazine for parents
                  covering an innovative look at parenting and kid's fashion.
                  The client commissioned a fashion shoot inspired by Japan and
                  Manga Culture.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <FashionSubheader
            client={'Kikimora'}
            credits={[
              'Photography: Joanna Szproch',
              'Styling: Bernadetta Nycz',
              'Makeup: Aneta Kacprzak',
            ]}
            buttonText="Client Website"
            buttonDestination="https://kikimora.com/"
          />
        </div>

        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col xs="12" className="mb-5 mb-lg-5 mt-lg-5">
                <img src={imgS8} alt="" className="img-fluid w-100" />
              </Col>
              <Col xs="12" className="mb-5 mb-lg-5 mt-lg-5">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-5 pr-lg-4">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-5 pr-lg-4">
                <img src={imgS4} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-5 pr-lg-4">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-5 pr-lg-4">
                <img src={imgS1} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS7} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS6} alt="" className="img-fluid w-100" />
              </Col>
            </Row>
          </Container>
        </Section>
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}
export default WorkSingle
