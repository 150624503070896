import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Section, Button, Title, Text, Box } from '../Core'

const FashionSubheader = ({
  client,
  credits,
  buttonText,
  buttonDestination,
}) => {
  const handleLiveWorkClicked = event => {
    event.stopPropagation()
    window.open(buttonDestination, '_blank')
  }

  return (
    <Container>
      <Row>
        <Col lg="4" className="mb-4 mb-lg-0">
          <Text variant="tag">Client</Text>
          <Title variant="cardBig" className="mt-3">
            {client}
          </Title>
        </Col>
        <Col lg="4" className="mb-4 mb-lg-0">
          <Text variant="tag">CREDITS</Text>
          <Title variant="cardSm" className="mt-3 d-flex flex-column">
            {credits.map(credit => (
              <span className="pb-2">{credit}</span>
            ))}
          </Title>
        </Col>
        <Col lg="4">
          <Button onClick={handleLiveWorkClicked} arrowRight>
            {buttonText}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default FashionSubheader
